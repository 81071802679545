import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { RangeSlider } from "flowbite-react";
import axiosInstance from "../../../axiosConfig";
import { Accordion } from "flowbite-react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { toast } from "react-toastify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default () => {
  const [submissions, setSubmissions] = useState([]);
  const [error, setError] = useState("");
  const { courseId } = useParams();
  useEffect(() => {
    axiosInstance
      .post("/api/submissions/get_all_published_grades_for_id.php", {
        class_id: courseId,
        user_id: localStorage.getItem("id"),
      })
      .then((response) => {
        console.log(response.data);
        setSubmissions(response.data);
      })
      .catch((error) => {
        // console.log(error);
        setError(error.message);
      });
  }, []);
  return (
    <React.Fragment>
      <div className="relative overflow-x-auto">
        <table className=" mt-10 w-full text-sm text-left text-gray-500 border dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Name
              </th>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Due
              </th>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Status
              </th>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Score
              </th>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Grade
              </th>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Comments
              </th>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Details
              </th>
            </tr>
          </thead>

          <tbody>
            {submissions.length == 0 && <div>No Grades found</div>}
            {submissions.map((submission) => {
              return (
                <React.Fragment>
                  <Submission
                    key={submission.assignment_id}
                    submission={submission}
                  />
                </React.Fragment>
              );
            })}
            <tr>
              <td colSpan={3}>Total</td>
              <td
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {sumByKey(submissions, "grade")}/
                {sumByKey(submissions, "assignment_total_marks")}
              </td>
              <td
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {assignGrade(
                  sumByKey(submissions, "grade"),
                  sumByKey(submissions, "assignment_total_marks")
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

function Submission({ submission }) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <tr className="bg-white  cursor-pointer  dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {submission.assignment_name}
        </th>
        <td
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {submission.assignment_due_date}
        </td>
        <td
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          <GradeStatus graded={submission.grade} />
        </td>
        <td
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {submission.grade ? submission.grade : "-"} /{" "}
          {submission.assignment_total_marks}
        </td>
        <td
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {assignGrade(submission.grade, submission.assignment_total_marks)}
        </td>
        <td
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
        >
          {submission.comments}
        </td>
        {submission.grade ? (
          <td
            className="hover:underline text-primary"
            onClick={() => setOpen(!open)}
          >
            {open !== true ? "View" : "Hide"} Stats
          </td>
        ) : (
          <td></td>
        )}
      </tr>
      {open && (
        <tr>
          <td colSpan={5}>
            <div className="border-b w-full px-40 py-12">
              <BoxPlotChart
                assignment_id={submission.assignment_id}
                grade={submission.grade}
              />
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}

function GradeStatus({ graded }) {
  return (
    <React.Fragment>
      {graded ? (
        <span className="bg-blue-100 text-primary text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
          Graded
        </span>
      ) : (
        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
          Not Graded
        </span>
      )}
    </React.Fragment>
  );
}

const BoxPlotChart = ({ assignment_id, grade }) => {
  const [dataset, setDataset] = useState();

  useEffect(() => {
    axiosInstance
      .post("/api/submissions/get_stats.php", {
        assignment_id: assignment_id,
      })
      .then((response) => {
        console.log(response.data);
        console.log(grade);
        console.log(populateChartData(response, grade));
        setDataset(populateChartData(response, grade));
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, []);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        // max: 100, // Adjust as needed based on your data
      },
    },
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Stats</h2>
      {dataset && <Bar data={dataset} options={options} />}
    </div>
  );
};

function extractAndSortArrays(response, grade) {
  // Extracting values from the response
  const values = [
    parseFloat(response.data.min_grade),
    parseFloat(response.data.max_grade),
    parseFloat(response.data.mean_grade),
    parseFloat(grade),
  ];
  console.log(values);

  // Creating an array of names
  const names = ["min", "max", "mean", "grade"];

  // Combining names and values into an array of objects
  const data = names.map((name, index) => ({ name, value: values[index] }));

  console.log(data);

  // Sorting the data array based on values in a custom order
  data.sort((a, b) => {
    if (a.name === "min") return -1;
    if (b.name === "min") return 1;
    if (a.name === "max") return 1;
    if (b.name === "max") return -1;
    return a.value - b.value; // Ascending order for other cases
  });
  console.log(data);

  // Extracting sorted names and values arrays
  const sortedNames = data.map((item) => item.name);
  const sortedValues = data.map((item) => item.value);

  return { sortedNames, sortedValues };
}

function populateChartData(response, grade) {
  // Extracting and sorting arrays
  const { sortedNames, sortedValues } = extractAndSortArrays(response, grade);
  console.log(sortedValues);
  // Mapping sortedNames to labels and setting background color based on the name
  const labels = sortedNames.map((name) => {
    let label = name.charAt(0).toUpperCase() + name.slice(1); // Capitalize the first letter
    if (name === "grade") {
      return "Your Score";
    }
    return label;
  });

  // Creating the datasets array for the chart with conditional background color
  const datasets = [
    {
      label: "Score",
      data: sortedValues, // Using the sorted values
      backgroundColor: sortedNames.map((name) =>
        name === "grade" ? "rgba(54, 162, 235, 0.2)" : "rgba(255, 206, 86, 0.2)"
      ),
      borderColor: sortedNames.map((name) =>
        name === "grade" ? "rgba(54, 162, 235, 1)" : "rgba(255, 206, 86, 1)"
      ),

      borderWidth: 1,
    },
  ];

  // Creating the final data object for the chart
  const data = {
    labels,
    datasets,
  };

  return data;
}

function assignGrade(individualMarks, totalMarks) {
  if (individualMarks === null) {
    return ""; // Return an empty grade if individual marks are null
  }

  let percentage = (individualMarks / totalMarks) * 100;

  if (percentage >= 90) {
    return "A";
  } else if (percentage >= 80) {
    return "B";
  } else if (percentage >= 70) {
    return "C";
  } else {
    return "D";
  }
}

function sumByKey(array, key) {
  return array.reduce((total, item) => {
    // Parse the value as an integer, treating null or undefined as 0
    return total + (parseInt(item[key]) || 0);
  }, 0); // Start with a total of 0
}
