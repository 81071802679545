import React, { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../../axiosConfig";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Modal, Button, Dropdown } from "flowbite-react";
import ThemedModal from "../../../components/ThemedModal";
import Error from "../../../components/Error";
import { convertToLocalTimeZone } from "../../../utilities/utilities";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { toast } from "react-toastify";

export default () => {
  const [modules, setModules] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [error, setError] = useState("");
  const { courseId, assignmentId } = useParams();
  const [openModal, setOpenModal] = useState();
  const [loading, setLoading] = useState(false);
  const props = { openModal, setOpenModal };
  const [assignment, setAssignment] = useState({});
  const [modifygrade, setModifyGrade] = useState({
    grade: "",
    comments: "",
  });
  const [stats, setStats] = useState(false);
  useEffect(() => {
    axiosInstance
      .post("/api/assignments/get_assignment_by_id.php", {
        assignment_id: assignmentId,
      })
      .then((response) => {
        console.log(response.data);
        setAssignment(response.data);
      })
      .catch((error) => {
        // console.log(error);
        setError(error.message);
      });
  }, []);
  useEffect(() => {
    axiosInstance
      .post("/api/submissions/get_submissions_for_assignment.php", {
        assignment_id: assignmentId,
      })
      .then((response) => {
        console.log(response.data);
        setSubmissions(response.data);
      })
      .catch((error) => {
        // console.log(error);
        setError(error.message);
      });
  }, [openModal]);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    sendData(modifygrade);

    setLoading(false);
  }

  function sendData(modifygrade) {
    axiosInstance
      .post("/api/submissions/update_grade.php", modifygrade)
      .then((response) => {
        // console.log(response);
        setOpenModal(undefined);
        setModifyGrade({});
        setError("");
      })
      .catch((error) => {
        // console.log(error);
        setError(error.message);
        setModifyGrade({});
      });
  }

  return (
    <React.Fragment>
      <div className="relative overflow-x-auto">
        <UpdateModal
          props={props}
          modifygrade={modifygrade}
          setModifyGrade={setModifyGrade}
          error={error}
          handleSubmit={handleSubmit}
          maxmarks={submissions[0] && submissions[0].assignment_total_marks}
        />
        <div className="flex justify-between">
          <h6 className="my-4">Submissions</h6>
          <button
            className="mt-4 p-4 py-2 rounded underline bg-primary text-white"
            onClick={() => setStats(!stats)}
          >
            {" "}
            View Stats
          </button>
        </div>

        {stats && <BoxPlotChart />}
        <table className=" mt-4 w-full text-sm text-left text-gray-500 border dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Name
              </th>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                submitted file
              </th>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Date
              </th>

              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Marks
              </th>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Status
              </th>
              <th scope="col" className="px-6 py-3 bg-primary text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((submission) => {
              return (
                <Submission
                  key={submission.user_id}
                  props={{
                    submission,
                    setModifyGrade,
                    setOpenModal,
                    assignment,          
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

function Submission({ props }) {
  const { submission, setModifyGrade, setOpenModal, assignment } = props;
  console.log(assignment);

  return (
    <tr className="bg-white border-b cursor-pointer dark:bg-gray-800 dark:border-gray-700">
      <th
        scope="row"
        className="px-6 py-4 font-medium hover:underline text-gray-900 whitespace-nowrap dark:text-white"
      >
        <Link className="hover:underline " href="#">
          {submission.first_name + " " + submission.last_name}
        </Link>
      </th>
      <th
        scope="row"
        className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
      >

        <FileStatus assignment={assignment} submission={submission} />
      </th>
      <th
        scope="row"
        className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
      >
        {submission.submission_attachments
          ? convertToLocalTimeZone(submission.submission_date)
          : ""}
      </th>

      <th
        scope="row"
        className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
      >
        {submission.grade ? submission.grade : 0}/
        {submission.assignment_total_marks}
      </th>
      <th
        scope="row"
        className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
      >
        {Graded(submission.grade)}
      </th>
      <th
        scope="row"
        className="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white"
      >
        <button
          type="button"
          className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
          onClick={() => {
            setOpenModal("default");
            setModifyGrade(submission);
          }}
        >
          Update
        </button>
      </th>
    </tr>
  );
}

function FileStatus({assignment, submission}) {
  console.log(assignment);
  if (assignment.assignment_type == "exam") {
    if (submission.submission_content == null) {
      return (
        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
          Not submitted
        </span>
      );
    } else {
      return (
        // <a
        //   className="text-primary hover:underline"
        //   href={submission.submission_attachments}
        //   target="_blank"
        // >
        //   View File
        // </a>
        <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
           submitted
        </span>
      );
    }
  } else {
    if (submission.submission_attachments) {
      return (
        <a
          className="text-primary hover:underline"
          href={submission.submission_attachments}
          download
        >
          View File
        </a>
      );
    } else {
      return (
        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
          Not submitted
        </span>
      );
    }
  }
}

function UpdateModal({
  props,
  modifygrade,
  setModifyGrade,
  error,
  handleSubmit,
  maxmarks,
}) {
  return (
    <ThemedModal
      show={props.openModal === "default"}
      onClose={() => {
        {
          setModifyGrade({});
          props.setOpenModal(undefined);
        }
      }}
    >
      <Modal.Header>Modify Grade</Modal.Header>
      <Modal.Body>
        {error && <Error message={error} />}
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label for="grade">Grade</label>
            <input
              type="number"
              step="any"
              max={maxmarks}
              onChange={(e) => {
                setModifyGrade({ ...modifygrade, grade: e.target.value });
              }}
              value={modifygrade.grade}
              required
            />
          </div>
          <div className="mb-6">
            <label for="comments">Comments</label>
            <input
              type="name"
              onChange={(e) => {
                setModifyGrade({ ...modifygrade, comments: e.target.value });
              }}
              value={modifygrade.comments}
              required
            />
          </div>

          <div className="mb-6 flex">
            <button
              type="submit"
              className="text-white mr-4 bg-primary   focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-primary dark:focus:ring-blue-800"
            >
              Submit
            </button>
            <Button
              color="gray"
              onClick={() => {
                setModifyGrade({});
                props.setOpenModal(undefined);
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal.Body>
    </ThemedModal>
  );
}

function Graded(grade) {
  return (
    <React.Fragment>
      {grade ? (
        <span className="bg-blue-100 text-primary text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
          Graded
        </span>
      ) : (
        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
          Not Graded
        </span>
      )}
    </React.Fragment>
  );
}

const BoxPlotChart = ({ assignment_id }) => {
  const [dataset, setDataset] = useState();
  const { assignmentId } = useParams();
  useEffect(() => {
    axiosInstance
      .post("/api/submissions/get_stats.php", {
        assignment_id: assignmentId,
      })
      .then((response) => {
        console.log(response.data);
        console.log(populateChartData(response));
        setDataset(populateChartData(response));
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, []);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        // max: 100, // Adjust as needed based on your data
      },
    },
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Stats</h2>
      {dataset && <Bar data={dataset} options={options} />}
    </div>
  );
};

function extractAndSortArrays(response) {
  // Extracting values from the response
  const values = [
    parseFloat(response.data.min_grade),
    parseFloat(response.data.max_grade),
    parseFloat(response.data.mean_grade),
  ];
  console.log(values);

  // Creating an array of names
  const names = ["min", "max", "mean"];

  // Combining names and values into an array of objects
  const data = names.map((name, index) => ({ name, value: values[index] }));

  console.log(data);

  // Sorting the data array based on values in a custom order
  data.sort((a, b) => {
    if (a.name === "min") return -1;
    if (b.name === "min") return 1;
    if (a.name === "max") return 1;
    if (b.name === "max") return -1;
    return a.value - b.value; // Ascending order for other cases
  });
  console.log(data);

  // Extracting sorted names and values arrays
  const sortedNames = data.map((item) => item.name);
  const sortedValues = data.map((item) => item.value);

  return { sortedNames, sortedValues };
}

function populateChartData(response, grade) {
  // Extracting and sorting arrays
  const { sortedNames, sortedValues } = extractAndSortArrays(response, grade);
  console.log(sortedValues);
  // Mapping sortedNames to labels and setting background color based on the name
  const labels = sortedNames.map((name) => {
    let label = name.charAt(0).toUpperCase() + name.slice(1); // Capitalize the first letter
    if (name === "grade") {
      return "Your Score";
    }
    return label;
  });

  // Creating the datasets array for the chart with conditional background color
  const datasets = [
    {
      label: "Score",
      data: sortedValues, // Using the sorted values
      backgroundColor: sortedNames.map((name) =>
        name === "grade" ? "rgba(54, 162, 235, 0.2)" : "rgba(255, 206, 86, 0.2)"
      ),
      borderColor: sortedNames.map((name) =>
        name === "grade" ? "rgba(54, 162, 235, 1)" : "rgba(255, 206, 86, 1)"
      ),

      borderWidth: 1,
    },
  ];

  // Creating the final data object for the chart
  const data = {
    labels,
    datasets,
  };

  return data;
}
