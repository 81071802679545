import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../axiosConfig";
import { toast } from "react-toastify";
import { Modal, Button, Dropdown } from "flowbite-react";
import ThemedModal from "../../../components/ThemedModal";
import { convertToTimeZone } from "../../../utilities/utilities";
import { useNavigate } from "react-router-dom";

const Exam = () => {
  const { assignmentId } = useParams();
  const [assignment, setAssignment] = useState({});
  const [submissionTimePassed, setSubmissionTimePassed] = useState(true);
  const [error, setError] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [openModal, setOpenModal] = useState();
  const [submissions, setSubmissions] = useState([]);
  const navigate = useNavigate();

  function fetchSubmissions() {
    axiosInstance
      .post("/api/submissions/get_submission_for_assignment_by_user.php", {
        assignment_id: assignmentId,
        user_id: localStorage.getItem("id"),
      })
      .then((response) => {
        // console.log(response.data);
        setSubmissions(response.data);
      })
      .catch((error) => {
        // console.log(error);
        setError(error.message);
      });
  }
  useEffect(() => {
    fetchSubmissions();
  }, []);

  useEffect(() => {
    axiosInstance
      .post("/api/assignments/get_assignment_by_id.php", {
        assignment_id: assignmentId,
      })
      .then((response) => {
        setAssignment(response.data);
        setSubmissionTimePassed(
          new Date(
            convertToTimeZone(
              response.data.assignment_available_date,
              "America/Chicago"
            )
          ) > new Date()
        );

        // Parse assignment content to extract questions
        try {
          const content = JSON.parse(response.data.assignment_content);
          if (content && content.questions) {
            setQuestions(content.questions);
          } else {
            setError(
              "Invalid JSON structure in assignment content: Missing 'questions' array."
            );
          }
        } catch (error) {
          setError(
            "Error parsing JSON in assignment content: " + error.message
          );
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  const handleSelect = (questionId, selectedOptionIndex) => {
    setAnswers({
      ...answers,
      [questionId]: selectedOptionIndex,
    });
  };

  const handleSubmit = () => {
    // Handle submission logic (e.g., send answers to the server)
    console.log("Submitted Answers:", answers);
    console.log("Grade:", gradeExam(answers, questions));
    axiosInstance
      .post("/api/submissions/add_exam_submission.php", {
        assignment_id: assignmentId,
        user_id: localStorage.getItem("id"),
        content: JSON.stringify(answers),
        attachments_link: "",
        grade: gradeExam(answers, questions),
      })
      .then((response) => {
        console.log(response.data);
        toast.success("Submission Successful");
        navigate(0);

        // console.log(response.data);
      })
      .catch((error) => {});
  };
  const props = { openModal, setOpenModal, handleSubmit, answers, questions };

  return (
    <div>
      {submissions.length > 0 ? (
        <div>
          {" "}
          Exam successfully submitted. Go to Grades to Track performance
        </div>
      ) : (
        <div className="mx-auto max-w-3xl border border-primary px-8">
          <ConfirmModal props={props} />
          <div>
            <h5 className="mb-4 text-4xl font-bold tracking-tight text-primary dark:text-white">
              Exam
            </h5>
            <Timer initialTime={600} assignmentId={assignmentId} />
          </div>

          {questions.map((question) => (
            <div className="py-8">
              <Question
                key={question.id}
                index={question.id}
                question={question.text}
                options={question.options}
                onSelect={(selectedOption) =>
                  handleSelect(question.id, selectedOption)
                }
              />
            </div>
          ))}
          <button
            className="bg-primary text-white px-4 py-2 rounded my-6"
            onClick={() => setOpenModal("default")}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

const Question = ({ question, options, onSelect, index }) => {
  return (
    <div className=" p-6 border border-primary rounded-lg">
      <h3 className="font-bold mb-4">{index + ") " + question}</h3>
      <ul className="pl-6 mb-4">
        <form>
          {options.map((option, index) => (
            <li key={index}>
              <label>
                <div className="flex  items-center">
                  <div>
                    <input
                      className="cursor-pointer"
                      type="radio"
                      name="answer"
                      value={index}
                      onChange={() => onSelect(index)}
                    />
                  </div>
                  <div className="ml-4">{option}</div>
                </div>
              </label>
            </li>
          ))}
        </form>
      </ul>
    </div>
  );
};

const gradeExam = (answers, questions) => {
  console.log(questions, answers);
  let totalPoints = 0;

  for (const [questionId, selectedOptionIndex] of Object.entries(answers)) {
    const question = questions[questionId - 1];
    console.log(question, questionId, selectedOptionIndex);

    if (!question || !question.options[selectedOptionIndex]) {
      // Handle invalid question or selected option
      console.error(
        `Invalid question or selected option for question ${questionId}`
      );
      continue;
    }

    const correctOptionIndex = question.correctOption;

    if (selectedOptionIndex == correctOptionIndex) {
      // Correct answer, add points to the total
      totalPoints += question.points;
    }
  }

  return totalPoints;
};

function ConfirmModal({ props }) {
  const { answeredQuestions, unansweredQuestions, totalQuestions } =
    calculateQuestionStats(props.answers, props.questions);
  return (
    <ThemedModal
      show={props.openModal === "default"}
      onClose={() => {
        {
          props.setOpenModal(undefined);
        }
      }}
    >
      <Modal.Header>Confirm Submission</Modal.Header>
      <Modal.Body>
        {" "}
        <div className="mb-6 font-bold text-primary">
          Once Submitted You can No longer modify Answers. Are you sure want to
          submit?
        </div>
        <div className="mb-6">Total questions given : {totalQuestions}</div>
        <div className="mb-6 ">Questions Answered : {answeredQuestions}</div>
        {unansweredQuestions !== 0 ? (
          <div className="mb-6 text-red-500">
            Questions UnAnswered : {unansweredQuestions}
          </div>
        ) : (
          <div className="mb-6 text-green-500">
            Questions UnAnswered : {unansweredQuestions}
          </div>
        )}
        <div className="mb-6 flex">
          <button
            onClick={props.handleSubmit}
            className="text-white mr-4 bg-primary   focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-primary dark:focus:ring-blue-800"
          >
            Submit
          </button>
          <Button
            color="gray"
            onClick={() => {
              props.setOpenModal(undefined);
            }}
          >
            Cancel
          </Button>
        </div>{" "}
      </Modal.Body>
    </ThemedModal>
  );
}

function calculateQuestionStats(answers, questions) {
  const answeredQuestions = Object.keys(answers).length;
  const unansweredQuestions = questions.length - answeredQuestions;

  return {
    answeredQuestions,
    unansweredQuestions,
    totalQuestions: questions.length,
  };
}

export default Exam;

function Timer({ initialTime, assignmentId }) {
  const initialTimeInSeconds = initialTime * 60;
  const [time, setTime] = useState(() => {
    // Initialize with value from localStorage or initialTime
    const storedTime = localStorage.getItem(`timer_${assignmentId}`);
    return storedTime ? parseInt(storedTime) : initialTime;
  });
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    let timer;

    if (isRunning && time > 0) {
      timer = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;
          console.log(time);
          console.log(isRunning);

          return newTime >= 0 ? newTime : 0;
        });
      }, 1000); // Update every 1 second (1000 milliseconds)
    }

    return () => {
      clearInterval(timer);
    };
  }, [isRunning, time]);

  useEffect(() => {
    localStorage.setItem(`timer_${assignmentId}`, time.toString());
  }, [assignmentId, time]);

  const hours = Math.floor(time / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (time % 60).toString().padStart(2, "0");

  return (
    <div>
      <h6>
        Timer: {hours}:{minutes}:{seconds}
      </h6>
    </div>
  );
}
