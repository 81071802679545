import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../axiosConfig";

export default ({ toggleSidebar }) => {
  const [user, setUser] = useState({});
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  function toggleDropdown() {
    setDropdown(!dropdown);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    axiosInstance
      .post("/api/profile/get_profile.php")
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);
  return (
    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-start">
          <button
            onClick={toggleSidebar}
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <span className="sr-only">Open sidebar</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
              ></path>
            </svg>
          </button>
          <div>
            <a href="/" className=" ml-2 md:mr-24">
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                Skillify
              </span>
            </a>
          </div>
          <div className="flex-1 flex justify-center">
            <a href="/" className=" ml-2 md:mr-24">
              <span className="self-center text-primary text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                {(user.Role === "student"
                  ? "Student"
                  : user.Role === "instructor"
                  ? "Instructor"
                  : user.Role === "admin"
                  ? "Admin"
                  : user.Role === "pc"
                  ? "Coordinator"
                  : user.Role === "qao"
                  ? "Quality Assurance Officer"
                  : "") + " Portal"}
              </span>
            </a>
          </div>
          <div ref={dropdownRef}>
            <div
              onClick={toggleDropdown}
              className="relative w-10 h-10 overflow-hidden cursor-pointer bg-gray-100 rounded-full dark:bg-gray-600"
            >
              <svg
                className="absolute w-12 h-12 text-gray-400 -left-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>

            <div
              onBlur={() => setDropdown(false)}
              className={`z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 ${
                dropdown ? "block absolute right-0 top-12" : "hidden"
              }`}
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDelayButton"
              >
                <li>
                  <div
                    href="/"
                    onClick={() => {
                      localStorage.removeItem("role");

                      localStorage.removeItem("id");

                      window.location.href = "/";
                    }}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Sign out
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
