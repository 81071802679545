import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../axiosConfig";
import { toast } from "react-toastify";
import { Modal, Button, Dropdown } from "flowbite-react";
import ThemedModal from "../../../components/ThemedModal";
import { convertToTimeZone } from "../../../utilities/utilities";
import { useNavigate } from "react-router-dom";

const Exam = () => {
  const { assignmentId } = useParams();
  const [error, setError] = useState("");
  const [assignment, setAssignment] = useState({});
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    axiosInstance
      .post("/api/assignments/get_assignment_by_id.php", {
        assignment_id: assignmentId,
      })
      .then((response) => {
        setAssignment(response.data);

        // Parse assignment content to extract questions
        try {
          const content = JSON.parse(response.data.assignment_content);
          if (content && content.questions) {
            setQuestions(content.questions);
          } else {
            setError(
              "Invalid JSON structure in assignment content: Missing 'questions' array."
            );
          }
        } catch (error) {
          setError(
            "Error parsing JSON in assignment content: " + error.message
          );
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  return (
    <div>
      <div className="mx-auto my-8 max-w-3xl border border-primary px-8">
        <div>
          <h5 className="my-4 text-4xl font-bold tracking-tight text-primary dark:text-white">
            Exam
          </h5>
        </div>

        {questions.map((question) => (
          <div className="py-8">
            <Question
              key={question.id}
              index={question.id}
              question={question.text}
              options={question.options}
            />
          </div>
        ))}
        <button className="bg-primary text-white px-4 py-2 rounded my-6">
          Submit
        </button>
      </div>
    </div>
  );
};

const Question = ({ question, options, onSelect, index }) => {
  return (
    <div className=" p-6 border border-primary rounded-lg">
      <h3 className="font-bold mb-4">{index + ") " + question}</h3>
      <ul className="pl-6 mb-4">
        <form>
          {options.map((option, index) => (
            <li key={index}>
              <label>
                <div className="flex  items-center">
                  <div>
                    <input
                      className="cursor-pointer"
                      type="radio"
                      name="answer"
                      value={index}
                    />
                  </div>
                  <div className="ml-4">{option}</div>
                </div>
              </label>
            </li>
          ))}
        </form>
      </ul>
    </div>
  );
};

export default Exam;
