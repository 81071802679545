import React from "react";
import { useRef, useState } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import axiosInstance from "../../axiosConfig";

export default () => {
  return (
    <React.Fragment>
      <h6 className="">Skillify AI Theme Changer</h6>
      <AiChat />
    </React.Fragment>
  );
};

const AiChat = () => {
  const messagesContainerRef = useRef(null);
  const [typing, setTyping] = useState(false);
  const [message, setMessage] = useState("");
  const { handleChangeTheme } = React.useContext(ThemeContext);
  const [messages, setMessages] = useState(
    [<Sendermessage message={"Hello How can I help You"} />],
    { persistState: true }
  );
  function handleSend(e) {
    e.preventDefault();
    if (message === "") {
      //   alert("Please enter a message");
      return;
    }
    console.log(message);
    setMessages((prevmessages) => [
      ...prevmessages,
      <ReceiverMessage message={message} />,
    ]);
    setMessage("");

    if (
      containsWords(message, [
        "color",
        "theme",
        "ui",
        "user interface",
        "interface",
        "colour",
      ])
    ) {
      console.log("color");
      getAiResponseColor(message).then((message) => {
        const color = findFirstColorInSentence(message);
        console.log(color);
        if (color) {
          handleChangeTheme(color, handleChangeTheme);
        }
      });
    }
    if (containsWords(message, ["font", "text"])) {
      console.log("font");

      getAiResponseFont(message).then((message) => {
        const font = findFirstFontInSentence(message);
        console.log(font);
        if (font) {
          changeFontFamily(font);
        }
      });
    }
  }

  return (
    <div className="h-full flex flex-col">
      <MessageBoxTitle onclick={onclick} name={"AI"} role={"AI"} />
      <div
        id="messages"
        className=" flex-col grow space-y-4 p-3 overflow-y-auto  "
        ref={messagesContainerRef}
      >
        {messages}
        {typing && <ReceiverMessage message="Typing..." />}
      </div>

      <SenderInput props={{ setMessage, message, handleSend }} />
    </div>
  );
};

function ChangeTheme(color, handleChangeTheme) {
  console.log(color);
  handleChangeTheme(color);
}

function Sendermessage({ message, image }) {
  return (
    <div className="chat-message ">
      <div className="flex  items-end">
        <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
          <div>
            <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none  text-gray-600  border border-primary">
              {message}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function ReceiverMessage({ image, message }) {
  return (
    <div className="chat-message">
      <div className="flex items-end justify-end">
        <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
          <div>
            <span className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-primary text-white ">
              {message}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function SenderInput({ props }) {
  const { setMessage, message, handleSend } = props;

  return (
    <div className="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0">
      <form onSubmit={handleSend}>
        <div className="relative flex">
          <input
            type="text"
            placeholder="Write your message!"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            value={message}
            className="flex-1 block focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3"
          />
          <div className="absolute right-0 items-center inset-y-0  sm:flex">
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-primary focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-6 w-6 ml-2 transform rotate-90"
              >
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function MessageBoxTitle({ onclick, name, image, role }) {
  return (
    <div className="flex sm:items-center justify-between py-3 border-b-2 border-gray-200">
      <div className="relative flex items-center space-x-4">
        <div className="flex flex-col leading-tight">
          <div className="text-2xl mt-1 flex items-center">
            <span className="text-gray-700 mr-3">{name}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function findFirstColorInSentence(sentence) {
  const colors = [
    "forest",
    "summer",
    "green",
    "red",
    "blue",
    "yellow",
    "orange",
    "purple",
    "pink",
    "teal",
    "brown",
    "gray",
    "cyan",
    "indigo",
    "lavender",
    "maroon",
    "olive",
    "silver",
    "violet",
    "peach",
  ];

  const lowercaseSentence = sentence.toLowerCase();

  for (const color of colors) {
    const lowercaseColor = color.toLowerCase();
    if (lowercaseSentence.indexOf(lowercaseColor) !== -1) {
      return color; // Return the first found color
    }
  }

  return ""; // Return null if no color is found
}

function findFirstFontInSentence(sentence) {
  const fonts = [
    "custom-sans",
    "custom-serif",
    "custom-mono",
    "arial",
    "times-new-roman",
    "helvetica",
    "georgia",
    "calibri",
    "lucida-sans",
    "palatino",
    "tahoma",
    "verdana",
    "courier-new",
    "impact",
    "book-antiqua",
    "garamond",
    "cambria",
    "trebuchet-ms",
    "franklin-gothic",
    "century-gothic",
    "rockwell",
  ];

  const lowercaseSentence = sentence.toLowerCase();

  for (const font of fonts) {
    const lowercaseFont = font.toLowerCase();
    if (lowercaseSentence.indexOf(lowercaseFont) !== -1) {
      return font; // Return the first found color
    }
  }

  return ""; // Return null if no color is found
}

async function getAiResponseColor(message) {
  return axiosInstance
    .post("http://localhost:3002/color", {
      message: message,
    })
    .then((res) => {
      console.log(res.data.text);
      return res.data.text;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

async function getAiResponseFont(message) {
  return axiosInstance
    .post("http://localhost:3002/font", {
      message: message,
    })
    .then((res) => {
      console.log(res.data.text);
      return res.data.text;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

function extractEmojis(sentence) {
  const emojiRegex =
    /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu;
  const emojis = [];

  const matches = sentence.match(emojiRegex);

  if (matches) {
    for (const match of matches) {
      emojis.push(match);
    }
  }

  return emojis;
}

const changeFontFamily = (fontClass) => {
  document.body.style.fontFamily = getFontFamilyFromClass(fontClass);
};

const getFontFamilyFromClass = (fontClass) => {
  switch (fontClass) {
    case "custom-sans":
      return "CustomSans, sans";
    case "custom-serif":
      return "CustomSerif, serif";
    case "custom-mono":
      return "CustomMono, monospace";
    case "arial":
      return "Arial, sans";
    case "times-new-roman":
      return "Times New Roman, serif";
    case "helvetica":
      return "Helvetica, sans";
    case "georgia":
      return "Georgia, serif";
    case "calibri":
      return "Calibri, sans";
    case "lucida-sans":
      return "Lucida Sans, sans";
    case "palatino":
      return "Palatino, serif";
    case "tahoma":
      return "Tahoma, sans";
    case "verdana":
      return "Verdana, sans";
    case "courier-new":
      return "Courier New, monospace";
    case "impact":
      return "Impact, sans";
    case "book-antiqua":
      return "Book Antiqua, serif";
    case "garamond":
      return "Garamond, serif";
    case "cambria":
      return "Cambria, serif";
    case "trebuchet-ms":
      return "Trebuchet MS, sans";
    case "franklin-gothic":
      return "Franklin Gothic, sans";
    case "century-gothic":
      return "Century Gothic, sans";
    case "rockwell":
      return "Rockwell, serif";
    default:
      return "sans-serif"; // Default font-family
  }
};

function containsWords(sentence, colorWords) {
  for (let word of colorWords) {
    if (sentence.toLowerCase().includes(word.toLowerCase())) {
      return true; // Returns true as soon as one of the words is found
    }
  }
  return false; // Returns false if none of the words are found
}
